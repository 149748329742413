<template>
  <v-card flat>
    <v-card-title>
      <h2 class="header text-primary">
        Behovsafdækningsrapport
      </h2>
    </v-card-title>
    <v-card-text>
      <PrintableRegion
        fileName="Behovsafdækningsrapport.pdf"
        header="Behovsafdækningsrapport"
        css="
.mb-1 { margin-bottom: 4px; }
.pl-4 { padding-left: 4px; }

i.mdi-checkbox-marked,
i.mdi-checkbox-blank-outline {
  font-style: normal;
  padding-right: 1em;
}

i.mdi-checkbox-marked::before {
  content: '☑'
}

i.mdi-checkbox-blank-outline::before {
  content: '☐'
}
      "
      >
        <p>Dette er din dannede rapport for dine behov ifm. indkøb</p>
        <!-- Behovsafdækning (sortimentsklarlægning) -->
        <template v-if="questions[0]">
          <h2>Sortimentsklarlægning</h2>
          <br />
          <ReportBoxDiagram :reportModel />
        </template>
        <div>
          <!-- Behovsafdækning (obligatoriske spørgsmål) -->
          <div>
            <h2>Kontrakttilpasninger - obligatoriske</h2>
            <br />
            <div v-for="question in requiredQuestions" :key="question.questionId">
              <div
                v-for="subquestion in question.questions || []"
                :key="subquestion.questionId"
              >
                <v-row class="align-center">
                  <v-col>
                    <div>
                      <v-checkbox-btn
                        disabled
                        :model-value="questionValue(subquestion)"
                        :label="subquestion.title"
                      />
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <ReportWarning
                      v-if="childQuestionEnabled(subquestion)"
                      :question="firstChildQuestion(subquestion)!"
                      :iconSize="20"
                    />
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </div>
          </div>
          <!-- Behovsafdækning (anbefalede spørgsmål) -->
          <div>
            <h2>Kontrakttilpasninger - anbefalede</h2>
            <br />
            <div
              v-for="question in recommendedQuestions"
              :key="question.questionId"
            >
              <v-row class="align-center">
                <v-col>
                  <div>
                    <v-checkbox-btn
                      disabled
                      :value="questionValue(question)"
                      :label="question.title"
                    />
                  </div>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <ReportWarning
                  v-if="childQuestionEnabled(question)"
                  :question="firstChildQuestion(question)!"
                  :iconSize="25"
                  class="mb-4"
                />
              </v-row>
              <v-divider></v-divider>
            </div>
          </div>
        </div>
      </PrintableRegion>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {
  Question,
  PrintableRegion,
  SimpleQuestion,
  ReportBoxDiagram,
  ReportWarning,
} from '@dims/components';
import { computed } from 'vue';

const { questions } = defineProps<{
  questions: Question[] }>();

// Filter out the recurring 'Kapacitet og standardbestillingsydelser' question from the report
const capacityAndStandardOrderServices = 'Kapacitet og standardbestillingsydelser';

const requiredQuestions = computed(() => questions[1]?.questions ?? []);

const recommendedQuestions = computed(() => (
  questions[2]?.questions?.filter(
    (q) => q.title !== capacityAndStandardOrderServices
          && q.enabled,
  ) ?? []
));

const reportModel = computed(() => (questions[0]?.questions ?? []).map((q) => ({
  title: q.title ?? '(Titel mangler)',
  selected: questionSelected(q),
  children: clarificationSubQuestions(q).map((subQuestion) => ({
    title: subQuestion.title ?? '(Titel mangler)',
    selected: questionSelected(q) && questionSelected(subQuestion),
  })),
})));

function questionSelected(question: Question) {
  const child = firstChildQuestion(question);
  return !!child && questionValue(child);
}

function questionValue(question: Question) {
  return (question as SimpleQuestion).value === 'true';
}

function clarificationSubQuestions(question: Question) {
  return (
    firstChildQuestion(question)?.questions?.filter(
      (q) => q.title !== capacityAndStandardOrderServices,
    ) ?? []
  );
}

function firstChildQuestion(question: Question) {
  return question.questions?.[0];
}

function childQuestionEnabled(question: Question) {
  return firstChildQuestion(question)?.enabled ?? false;
}

</script>

<style scoped>
.header {
  font-size: 30px;
}

</style>
