<template>
  <EvaluationOfferListBaseV2
    v-if="features.EvaluationV2"
  >
    <template #additional-columns></template>
    <template #tco></template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer
        :index
        :isOfferConditional="conditionalOffers.includes(offer)"
      />
    </template>
  </EvaluationOfferListBaseV2>
  <EvaluationOfferListBase
    v-else
    :tender="evaluationStore.tender"
    :offers="evaluationStore.offers"
    :offersEvaluations="evaluationStore.offersEvaluations"
    :additionalHeaders="[]"
    :isLoadingOffers="evaluationStore.isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="evaluationStore.fetchAndSortOffers(calculator)"
    @updateTab="emit('updateTab', $event)"
  >
    <template #additional-columns></template>
    <template #tco></template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer
        :index
        :isOfferConditional="conditionalOffers.includes(offer)"
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { EvaluationOfferListBase, useEvaluationStore, useFeatures } from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  updateTab: [string],
}>();

const features = useFeatures();
const calculator = getCalculator();
const evaluationStore = useEvaluationStore();

</script>
