<template>
  <v-row>
    <v-col>
      <v-table :class="tableStyle">
        <template #default>
          <tbody>
            <tr v-for="(question, idx) of questions" :key="idx">
              <td>
                <v-icon
                  class="mr-3"
                  :color="question.complete ? 'primary' : ''"
                >{{
                  question.complete
                    ? 'mdi-check-circle'
                    : 'mdi-close-circle-outline'
                }}</v-icon>{{ question.title }}
              </td>
              <td style="text-align: end">
                <div v-if="showPrice(question)">
                  {{ toCurrencyFormat(sumOfQuestion(question)) }}
                </div>
                <small v-else class="font-italic">
                  Kunden har fravalgt prispunkter under dette ydelsesområde,
                  hvorfor der ikke skal afgives priser</small>
              </td>
            </tr>
            <tr>
              <td>
                <div class="font-italic">I alt</div>
              </td>
              <td style="text-align: end">
                <div class="text-decoration-underline">
                  {{ toCurrencyFormat(sumOfQuestions) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-col>
    <v-col v-if="showTags" cols="auto" class="d-flex flex-column align-center">
      <TagInterval tag="D" :hoveredTag :start="0" :end="1" />
      <TagInterval tag="E" :hoveredTag :start="1" :end="2" />
      <TagInterval tag="F" :hoveredTag :start="2" :end="questions.length" />
    </v-col>
    <v-col v-if="showTags" cols="auto" class="d-flex flex-column align-center">
      <TagInterval tag="C" :hoveredTag :start="0" :end="2" />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Question, hidePrice, totalPrice, TagInterval, toCurrencyFormat } from '@dims/components';

const { questions, showTags = false, hoveredTag = undefined, tableStyle = undefined } = defineProps<{
  questions: Question[],
  showTags?: boolean,
  hoveredTag?: string,
  tableStyle?: string,
}>();

function showPrice(question: Question) {
  return question.questions ? !hidePrice(question.questions) : false;
}

function sumOfQuestion(question: Question) {
  return question.questions ? totalPrice(question.questions) : 0;
}

const sumOfQuestions = computed(() => questions.reduce(
  (sum, question) => sum + sumOfQuestion(question),
  0,
));

</script>
